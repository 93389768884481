* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-primary {
  font-family: "Segoe UI";
  font-weight: 600;
}

.mobileContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
 
.content {
  flex: 1;
  overflow-y: auto;
}
 
.pagination {
  position: sticky;
  bottom: 0;
  background-color: white; 
}

@media only screen and (max-width: 768px) {
  body {
    width: fit-content;
  }
}
